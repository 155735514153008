import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Blessed = () => (
  <Layout>
    <SEO
      title="Going Natural With Blessed"
      description="Zambian born Blessed talks about her decision to go natural. We discuss her top hairstyles and which products are best for natural hair."
    />
    <h1>Starting Again & Going Natural With Blessed</h1>
    <h2 style={{color:"Orchid"}}>Zambian born Blessed talks about her decision to go natural. We discuss her top hairstyles and which products are best for natural hair</h2>
    

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/YukYEIW.jpg"
        alt="Blessed natural hair braids"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Blessed Shaba</p>
    </div>

    <p>We sat down to have a chat with Blesssed Shaba. She made the decision to go natural in May and is enjoying the process.</p>

    <h3>Hello Blessed, tell us a bit about yourself</h3>
    <p>My name is Blessed, I am a 22 year old Zambian girl who is currently a student studying Criminology at Nottingham Trent University.</p>

    <h3>What does your natural hair mean to you and how would you describe your hair?</h3>
    <p>Natural hair to me is about my heritage, it is who I am, where I am from. Not too sure how to describe my hair apart from sometimes difficult to manage.</p>

    <h3>Talk us through your hair routine</h3>
    <p>A usual hair routine for me is a wash every two weeks. I do not have a shampoo I am dedicated to so at the moment I am just washing my hair with any shampoo. What I am dedicated to is pink hair lotion, which I apply after towel drying my hair, before blow drying. After blow drying my hair, I use another dedicated product to scalp with, pure coconut oil. I then style as I wish.</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/gVQ1wHK.jpg"
        alt="Blessed relaxed hair"
        style={{marginBottom: "5px"}}
      />
    </div>

    <h3>How did you view your hair growing up?</h3>
    <p>Growing up I saw my hair as a pain, didn’t want it. Every time someone did my hair, it felt like a fight.</p>

    <h3>Did you have a role model growing up that helped you?</h3>
    <p>My sister has been my role model growing up, she has helped me learn more about my hair and has shown me how to look after it. Once I was old enough to take care of my own hair, I did try to experiment by cutting myself a fringe, big mistake.</p>

    <h3>Any interesting/funny stories?</h3>
    <p>Growing up, I didn’t like my hair being done, I always felt attacked by the person doing it, mainly my sister, sometimes my mum. It hurt, I thought they didn’t care and love me by hurting me when doing my hair so I tried to run away from home once or twice. I only got as far as the back garden.</p>

    <h3>What advice would you give for taking care of natural hair?</h3>
    <p>Our hair isn’t like everyone else’s. Wrap it up, put a head scarf on, protect your hair. Don’t be ashamed to do what you have to do around others in order to look after your hair.</p>

    <h3>What is your favourite hairstyle right now?</h3>
    <p>At the moment, I am straightening my hair after every wash and dry, so twice a month maybe a little bit more and wearing clip in extensions.</p>

    <h3>Do you have any hair plans for the future and can you tell us about any products you use?</h3>
    <p>My future plans are to stop wearing extensions as in order for me to wear them, I have to straighten my hair so my hair blends with the extensions. Heat damage, blow drying as well as straighten. I may try wigs; a lot of girls are wearing wigs. Keep my hair in cornrows for maybe just over two weeks then wash and cornrow again, keep repeating to help the growth of my hair. Every so often, I will get braids done as well.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Qt8O8zg.jpg"
        alt="Blessed afro combed out"
        style={{marginBottom: "5px"}}
      />
    </div>

    <p>At the moment, I am using everyone around me to help with the growth and health of my hair. I am asking people what they do and taking bits of what they say away with me. I am sticking to products I know work well with my hair. I am not experimenting too much at the moment but I do want to find a good supplement, vitamin I can take that will help my hair.</p>

    <h3>How can we follow/ contact you? </h3>
    <p>The older I get, the less I bother with social media.</p>
    <p>Instagram <a
        href="https://www.instagram.com/beeabahs/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @Beeabahs
      </a></p>

      <h3 style={{color:"Orchid"}}>Note from the editor</h3>
      <p >It was great talking to Blessed. I have known her for a few years and learned some things I didn't know before. I had no idea she was thinking of going natural and was becoming more conscious of her hair care, thinking long term. I hope to follow Blessed's journey and talk to her again in a few months or so to see how she's getting on. Hopefully she can be an inspiration to those of you on the same path.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-with-sharna/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            Learning to Love Natural Hair with Sharna
            </Link>{" "}
            - Sharna tells us about her journey and learning to love & appreciate her natural hair. She talks about growing up mixed race and learning about herself...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Blessed
